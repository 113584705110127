<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-tabs pills>
          <b-tab active>
            <template #title>
              <feather-icon icon="SlidersIcon"/>
              <span>Role</span>
            </template>

            <b-row class="mt-1">

              <b-col cols="12">
                <validation-provider #default="validationContext" name="Name" rules="required">
                  <b-form-group label="Name">
                    <b-form-input trim placeholder="Name" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12">
                <validation-provider #default="validationContext" name="Role Type" rules="required">
                  <b-form-group label="Role Type" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.role_type" :options="roleTypeOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12">
                <validation-provider #default="validationContext" name="Status" rules="required">
                  <b-form-group label="Status" label-for="status" :state="getValidationState(validationContext)">
                    <v-select v-model="dataInfo.status" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

            </b-row>

          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon icon="ToolIcon"/>
              <span>Permissions</span>
            </template>
            <b-card no-body class="border mt-1">
              <b-table
                  :items="dataInfo.authorizations"
                  :fields="authorizationFields"
                  class="mb-0"
                  striped responsive bordered hover>
                <template #cell(permission_type)="data">
                  {{ data.value }}
                </template>
                <template #cell(permission_value)="data">
                  <b-form-checkbox :checked="data.value" @change="changeAuthorizationValue(data.item)"/>
                </template>
              </b-table>
            </b-card>
          </b-tab>

        </b-tabs>
        <action-buttons :back-route="'system-role-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BTabs, BTab, BTable, BFormCheckbox, BOverlay,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/system/role/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {getPermissionName, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import {toastMessage} from "@core/utils/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    BOverlay,
    BTabs,
    BTab,
    BTable,
    BFormCheckbox,

    vSelect,
    ActionButtons,
    Overlay,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: 0,
      name: '',
      role_type: 0,
      status: 1,
      authorizations: [],
    })

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'system-role-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const authorizationFields = [
      {key: 'permission_type', label: 'permission'},
      {key: 'permission_value', label: 'Value'}
    ];

    const roleTypeOptions = [
      {label: 'Normal', value: 0},
      {label: 'Athlete', value: 1},
    ]

    const changeAuthorizationValue = (auth) => {
      dataInfo.value.authorizations.forEach(function callback(value, index) {
        if (value.id === auth.id) {
          value.permission_value = !auth.permission_value
        }
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'system-role-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    return {
      busy,
      dataInfo,
      refFormObserver,
      authorizationFields,
      roleTypeOptions,

      statusOptions,

      onSubmit,
      getValidationState,
      resetForm,
      changeAuthorizationValue,
      getPermissionName,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
